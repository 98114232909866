import {
  AlignItems,
  Box,
  Button,
  ButtonGroup,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  Color,
  ColorPreset,
  Glyph,
  Icon,
  JustifyContent,
  Link,
  P,
  Space,
  TypePreset,
  Visibility,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useRouter } from "next/router";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import { forwardRef, useCallback, useEffect, useMemo } from "react";
import { useLocalStorage } from "src/common/hooks/useLocalStorage/useLocalStorage";
import { LinkBuilder, Route } from "src/components/routing";
import {
  getLocalTimeZone,
  now,
  parseZonedDateTime,
} from "@internationalized/date";
import { useUser } from "src/queries/user";
import { useUserEnforcePasswordReset } from "@gocardless/api/dashboard/user";
import {
  showMFAEnforcementDelayedBanner,
  useMFAEnforcementData,
} from "src/components/authentication/mfa/useMFAEnforcementData";
import { isInDom } from "src/common/isInDom";

export const STORAGE_KEY =
  "gc.user.dashboard_preference.two_factor_authentication_banner_dismissed";
const bannerName = "Two Factor Authentication";

export const useTwoFactorAuthenticationShowMessage = ({
  isBannerDismissedUntilDateString,
}: {
  isBannerDismissedUntilDateString: string;
}) => {
  const user = useUser();

  return useMemo(() => {
    if (user?.dashboard_preferences?.two_factor_auth_notification_dismissed) {
      return false;
    }

    if (!isBannerDismissedUntilDateString) {
      return true;
    }

    try {
      const snoozedTime = parseZonedDateTime(isBannerDismissedUntilDateString);
      const currentTime = now(getLocalTimeZone());
      const reactivationDate = snoozedTime.add({ days: 7 });

      return currentTime.compare(reactivationDate) > 0;
    } catch {
      // Always return true if we encounter an error parsing the stored date.
      return true;
    }
  }, [
    isBannerDismissedUntilDateString,
    user?.dashboard_preferences?.two_factor_auth_notification_dismissed,
  ]);
};
export const TwoFactorAuthenticationBanner = forwardRef<HTMLDivElement, {}>(
  (_props, ref) => {
    const isRefDefined = !!ref;
    //@ts-expect-error forwardRef is an union of the callback ref and ref objet, hence the error
    const nodeElement = ref?.current as HTMLElement;

    const mfaData = useMFAEnforcementData();
    const showMFAEnforcementBanner = showMFAEnforcementDelayedBanner(mfaData);
    const { data: enforcedPasswordResetData } = useUserEnforcePasswordReset();

    const hasRemainingDays =
      !!enforcedPasswordResetData?.remaining_days_to_reset_password;

    const [storageItem, setStorageItem] = useLocalStorage(STORAGE_KEY, "");

    const showBanner = useTwoFactorAuthenticationShowMessage({
      isBannerDismissedUntilDateString: storageItem,
    });

    const remindMeLater = () => {
      const snoozeDate = now(getLocalTimeZone());
      setStorageItem(snoozeDate.toString());
    };

    const { sendEvent } = useSegment();
    const router = useRouter();
    const triggerBannerViewedEvent = useCallback(() => {
      if (!isRefDefined || isInDom(nodeElement)) {
        sendEvent(TrackingEvent.BANNER_VIEWED, {
          page: router.pathname,
          name: bannerName,
        });
      }

      // TODO: Fix exhaustive dependencies
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.pathname, nodeElement, isRefDefined]);

    useEffect(() => {
      if (showBanner) {
        triggerBannerViewedEvent?.();
      }
    }, [showBanner, triggerBannerViewedEvent]);

    return showBanner && !hasRemainingDays && !showMFAEnforcementBanner ? (
      <Box
        ref={ref}
        gutterH={1}
        gutterV={1}
        borderRadius={1}
        layout="flex"
        flexDirection={["column", null, "row"]}
        alignItems={[AlignItems.Start, null, AlignItems.Center]}
        justifyContent={JustifyContent.SpaceBetween}
        bg={Color.Ultraviolet_50}
      >
        <Box>
          <Box
            layout="flex"
            flexDirection={["column", null, "row"]}
            alignItems={[AlignItems.Start, null, AlignItems.Center]}
          >
            <Icon name={Glyph.Padlock} size="16px" />
            <Space layout="inline" h={1} />
            <Visibility visible={["block", null, "none"]}>
              <Space v={1} />
            </Visibility>
            <P color={ColorPreset.TextOnLight_01} preset={TypePreset.Body_02}>
              <Trans id="2fa-banner.description">
                Add extra security by switching on two-factor authentication
                (2FA)
              </Trans>
            </P>
          </Box>
          <Visibility visible={["block", null, "none"]}>
            <Space v={1} />
          </Visibility>
        </Box>
        <Box width={["100%", null, "initial"]} flexShrink={[1, null, 0]}>
          <ButtonGroup arrangement={["column-center", null, "row-end"]}>
            <Button
              onClick={() => {
                remindMeLater();
                sendEvent(TrackingEvent.BANNER_DISMISSED, {
                  page: router.pathname,
                  name: bannerName,
                });
              }}
              variant={ButtonVariant.TextOnLight}
              size={ButtonSize.Sm}
              layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
            >
              <Trans id="2fa-banner.later-button-text">Later</Trans>
            </Button>

            <LinkBuilder
              route={Route.MFASetup}
              onClick={() => {
                sendEvent(TrackingEvent.BANNER_PRIMARY_CTA_CLICKED, {
                  page: router.pathname,
                  name: bannerName,
                  destination: Route.MFASetup,
                });
              }}
            >
              {(result) => (
                <Link
                  {...result}
                  variant={ButtonVariant.SecondaryOnLight}
                  size={ButtonSize.Sm}
                  layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
                >
                  <Trans id="2fa-banner.enable-button-text">Enable 2FA</Trans>
                </Link>
              )}
            </LinkBuilder>
          </ButtonGroup>
        </Box>
      </Box>
    ) : null;
  }
);
