import {
  AlignItems,
  Box,
  Breakpoint,
  ButtonSize,
  ButtonVariant,
  Color,
  ColorPreset,
  DateRangePicker,
  FontWeight,
  Glyph,
  H2,
  HoverEffect,
  IconButton,
  Icon,
  Interpose,
  JustifyContent,
  PlainButton,
  Separator,
  Space,
  Text,
  TextAlign,
  TypePreset,
  TypeScale,
  Visibility,
  XYGrid,
  useTheme,
  Tooltip,
  P,
} from "@gocardless/flux-react";
import { LastUpdatedText } from "src/components/routes/Home/components/LastUpdatedText";
import { useI18nLocale } from "src/common/hooks/useI18nLocale";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import {
  CalendarDate,
  getLocalTimeZone,
  parseDate,
  today,
} from "@internationalized/date";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import { mutate } from "swr";
import { useIsBreakpoint } from "src/common/hooks/useIsBreakpoint";
import useToggle from "src/common/hooks/useToggle";
import { useOrganisation } from "src/queries/organisation";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { useMerchantMaturity } from "src/components/routes/Home/hooks/useMerchantMaturity";

import { useReportingContext } from "./components/ReportingContextProvider";
import { ChartWidget } from "./components/ChartWidget/ChartWidget";
import { DateRangeMenu } from "./components/DateRangeMenu/DateRangeMenu";
import BetaWidget from "./components/BetaWidget/BetaWidget";
import { DataWidget } from "./components/DataWidget/DataWidget";
import CollectionCurrencyMenu from "./components/CollectionCurrencyMenu/CollectionCurrencyMenu";
import {
  accountHealthSectionDataWidgetTypes,
  todaySectionDataWidgetTypes,
} from "./components/DataWidget/utils";
import { FeedbackForm } from "./components/FeedbackForm/FeedbackForm";
import { ErrorBanner } from "./components/ErrorBanner/ErrorBanner";
import { getMostRecentDate } from "./utils/common";
import {
  betaWidgetContainerLargeBreakpointStyle,
  betaWidgetContainerMediumBreakpointStyle,
  betaWidgetContainerSmallBreakpointStyle,
  chartWidgetContainerStyle,
  mobileSeparatorStyle,
} from "./utils/style";
import { EducationalDialog } from "./components/EducationalDialog/EducationalDialog";
import { useCollectionCurrencies } from "./hooks/useCollectionCurrencies";

export const Reporting = () => {
  const { isOn: isFeedbackDialogOpen, toggle: toggleFeedbackDialog } =
    useToggle(false);

  const {
    isOn: isEducationalDialogOpen,
    on: openEducationalDialog,
    off: closeEducationalDialog,
  } = useToggle(false);

  const { fx_payout_currency: fxPayoutCurrency } = useOrganisation() ?? {};

  const { collectionCurrencies } = useCollectionCurrencies();

  const { isActivatedMerchant } = useMerchantMaturity();

  const { theme } = useTheme();
  const i18nLocale = useI18nLocale();
  const {
    setStartDateFilter,
    startDateFilter,
    endDateFilter,
    setEndDateFilter,
    failedRequestKeys,
    setFailedRequestKeys,
    updatedAtDates,
    isChartWidgetLoading,
  } = useReportingContext();

  const { isVariationOn: showAdditionalFeedbackQuestions } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.MERCHANT_ENGAGEMENT_REPORTING_V1_EXTRA_FEEDBACK_QUESTIONS,
    });

  const { sendEvent } = useSegment();
  const { i18n } = useLingui();

  const onClickFeedbackButton = () => {
    toggleFeedbackDialog();
    sendEvent(
      TrackingEvent.MERCHANT_DASHBOARD_REPORTING_FEEDBACK_BUTTON_CLICKED
    );
  };

  const handleDatePickerChange = (date: CalendarDate[]) => {
    if (!date.length) return;
    const startDateObj = date[0] as CalendarDate;
    const endDateObj = date[1] as CalendarDate;
    const startDate = new CalendarDate(
      startDateObj.year,
      startDateObj.month,
      startDateObj.day
    ).toString();
    const endDate = new CalendarDate(
      endDateObj.year,
      endDateObj.month,
      endDateObj.day
    ).toString();
    setStartDateFilter(startDate);
    setEndDateFilter(endDate);
  };

  const handleOnReload = () => {
    mutate(
      (key) => typeof key === "string" && failedRequestKeys.includes(key),
      undefined,
      { revalidate: true }
    );

    setFailedRequestKeys([]);
  };

  const selectedDateRange = [
    parseDate(startDateFilter),
    parseDate(endDateFilter),
  ];

  const lastUpdatedDate = getMostRecentDate(updatedAtDates);
  const yesterday = today(getLocalTimeZone()).subtract({ days: 1 });

  const showMultiCurrencyLayout = collectionCurrencies?.length > 1;

  const isSmallBreakpointOrHigher = useIsBreakpoint({
    min: Breakpoint.Sm,
  });

  const showHelp = isActivatedMerchant ? (
    isSmallBreakpointOrHigher ||
    (!isSmallBreakpointOrHigher && !showMultiCurrencyLayout) ? (
      <Box>
        <Icon name={Glyph.Bulb} size="12px" verticalAlign="middle" />
        <Space h={0.5} layout="inline" />

        <PlainButton
          effect={HoverEffect.TextDecoration}
          onClick={openEducationalDialog}
          weight={FontWeight.SemiBold}
        >
          <Text size={2}>
            <Trans>Help viewing previous data</Trans>
          </Text>
        </PlainButton>
      </Box>
    ) : (
      <Box borderColor={Color.Greystone_1400} borderRadius={2} borderWidth={2}>
        <IconButton
          icon={Glyph.Bulb}
          label={<Trans>Help viewing previous data</Trans>}
          onClick={openEducationalDialog}
          size={ButtonSize.Sm}
          variant={ButtonVariant.TextOnLight}
        />
      </Box>
    )
  ) : null;

  return (
    <Box>
      {!!failedRequestKeys.length && (
        <>
          <ErrorBanner
            onReload={handleOnReload}
            onClickContactSupport={toggleFeedbackDialog}
          />
          <Space v={3} />
        </>
      )}

      <XYGrid
        columnGap={2}
        rowGap={2}
        templateColumns={["1fr", "repeat(2, 1fr)", null, "repeat(3, 1fr)"]}
        templateAreas={[
          `
            "today"
            "accountHealth"
            "."
            "overview"
           `,
          `
            "today today"
            "accountHealth accountHealth"
            ". ."
            "overview overview"
            `,
          null,
          `
            "today today today"
            ". . ."
            "overview overview accountHealth"
            `,
        ]}
      >
        <Box gridArea="today">
          <Box
            layout="flex"
            alignItems={AlignItems.Center}
            justifyContent={JustifyContent.SpaceBetween}
            spaceBelow={1.5}
          >
            <H2 preset={TypePreset.Heading_04} textAlign={TextAlign.Left}>
              <Trans>Today</Trans>
            </H2>

            <Box layout="flex" alignItems={AlignItems.Center}>
              {showHelp}
              {showMultiCurrencyLayout && (
                <>
                  {showHelp && (
                    <Separator
                      color={ColorPreset.BorderOnLight_04}
                      css={{ margin: `0 ${theme.spacing(0.75)}` }}
                      direction="inline-vertical"
                    />
                  )}
                  <CollectionCurrencyMenu
                    currencies={collectionCurrencies}
                    fxPayoutCurrency={fxPayoutCurrency}
                  />
                </>
              )}
            </Box>
          </Box>

          <Box
            bg={[Color.White, Color.Brownstone_50]}
            borderColor={[ColorPreset.BorderOnLight_04, Color.White]}
            borderRadius={[1, 0]}
            borderWidth={[1, 0]}
            gutterH={[1.5, 0]}
            gutterV={[1.5, 0]}
          >
            <XYGrid
              columnGap={[0, 1.5, 2]}
              rowGap={[0, 1.5, 2]}
              templateColumns={[
                "1fr",
                "repeat(2, 1fr)",
                null,
                "repeat(3, 1fr)",
              ]}
            >
              <Interpose
                node={
                  <Separator align="center" css={mobileSeparatorStyle(theme)} />
                }
                trailing
              >
                {todaySectionDataWidgetTypes.map((dataWidgetType) => (
                  <DataWidget key={dataWidgetType} type={dataWidgetType} />
                ))}
              </Interpose>

              <Box css={betaWidgetContainerMediumBreakpointStyle(theme)}>
                <BetaWidget onButtonClick={onClickFeedbackButton} />
              </Box>
            </XYGrid>
          </Box>
        </Box>

        <H2 preset={TypePreset.Heading_04} textAlign={TextAlign.Left}>
          <Trans>Overview</Trans>
        </H2>

        <Box gridArea="overview" layout="flex" flexDirection="column">
          <Box spaceBelow={[1, 2]} layout="flex" alignItems={AlignItems.Center}>
            <Interpose node={<Space h={[1, 2]} layout="inline" />} trailing>
              <DateRangeMenu />
              <Box layout="flex" alignItems={AlignItems.Center}>
                <DateRangePicker
                  aria-label={i18n._(
                    t({
                      message: "Select custom chart date range",
                    })
                  )}
                  disabled={isChartWidgetLoading}
                  i18n={i18nLocale}
                  format="dd-mm-yyyy"
                  value={selectedDateRange}
                  onChange={handleDatePickerChange}
                  minDate={yesterday.subtract({ months: 3 })}
                  maxDate={yesterday}
                  triggerButton={{
                    style: {
                      padding: 0,
                      "&:hover": {
                        background: Color.Transparent,
                        textDecoration: "underline",
                      },
                      "&:focus": {
                        background: Color.Transparent,
                      },
                    },
                    children: (
                      <>
                        <Text preset={TypePreset.Heading_01}>
                          <Trans>Dates</Trans>
                        </Text>
                        <Space layout="inline" h={0.5} />
                        <Icon name={Glyph.Calendar} size="12px" />
                      </>
                    ),
                  }}
                />
              </Box>
            </Interpose>

            {lastUpdatedDate && (
              <>
                <Visibility visible={["none", null, "block"]}>
                  <Space h={1} layout="inline" />
                  <Text size={TypeScale.Size_01}>
                    <LastUpdatedText dateTimeString={lastUpdatedDate} />
                  </Text>
                  <Space layout="inline" h={0.75} />
                  <Tooltip
                    positionStrategy="absolute"
                    message={
                      <>
                        <P spaceBelow={1}>
                          <Trans>
                            GoCardless will refresh all data shown on a daily
                            basis just after midday (approximately 13:30 UTC).
                          </Trans>
                        </P>
                        <P>
                          <Trans>
                            This will mean data accuracy will vary depending on
                            when the homepage is viewed.
                          </Trans>
                        </P>
                      </>
                    }
                    triggeredBy="hover"
                  >
                    <Trans>
                      Information about reporting metric refresh rates
                    </Trans>
                  </Tooltip>
                </Visibility>
              </>
            )}
          </Box>

          <Box css={betaWidgetContainerSmallBreakpointStyle(theme)}>
            <BetaWidget onButtonClick={onClickFeedbackButton} />
          </Box>

          <Box css={chartWidgetContainerStyle(theme)} height="100%">
            <ChartWidget />
          </Box>
        </Box>

        <Box gridArea="accountHealth" layout="flex" flexDirection="column">
          <H2
            preset={TypePreset.Heading_04}
            textAlign={TextAlign.Left}
            spaceBelow={1.5}
          >
            <Trans>Account health</Trans>
          </H2>

          <Box
            bg={[Color.White, Color.Brownstone_50]}
            borderColor={[ColorPreset.BorderOnLight_04, Color.White]}
            borderRadius={[1, 0]}
            borderWidth={[1, 0]}
            gutterH={[1.5, 0]}
            gutterV={[1.5, 0]}
            flexGrow={1}
          >
            <XYGrid
              columnGap={[0, 1.5, 2]}
              rowGap={[0, 1.5, 2]}
              templateColumns={["1fr", "repeat(2, 1fr)", null, "1fr"]}
              height="100%"
            >
              <Interpose
                node={
                  <Separator align="center" css={mobileSeparatorStyle(theme)} />
                }
                trailing
              >
                {accountHealthSectionDataWidgetTypes.map((dataWidgetType) => (
                  <DataWidget key={dataWidgetType} type={dataWidgetType} />
                ))}
              </Interpose>
              <Box css={betaWidgetContainerLargeBreakpointStyle(theme)}>
                <BetaWidget onButtonClick={onClickFeedbackButton} />
              </Box>
            </XYGrid>
          </Box>
        </Box>
      </XYGrid>
      <EducationalDialog
        isOpen={isEducationalDialogOpen}
        onClose={closeEducationalDialog}
      />
      <FeedbackForm
        isDialogOpen={isFeedbackDialogOpen}
        toggleDialog={toggleFeedbackDialog}
        showAdditionalQuestions={showAdditionalFeedbackQuestions}
      />
    </Box>
  );
};
