import { Fragment } from "react";
import { PrimaryPageLayout } from "src/components/layout";
import { Interpose, Space } from "@gocardless/flux-react";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useSendPageViewEvent } from "src/technical-integrations/segment/useSendPageViewEvent";
import { TrackingEvent } from "src/common/trackingEvents";
import { Route } from "src/common/routing";

import { ReportingContextProvider } from "../../ui/Reporting/components/ReportingContextProvider";
import { Reporting } from "../../ui/Reporting";

import { useMerchantMaturity } from "./hooks/useMerchantMaturity";
import { Header } from "./sections/Header";
import { ActionItems } from "./sections/ActionItems";
import { CreditorBalance } from "./sections/CreditorBalance";
import { QuickActions } from "./sections/QuickActions";
import { NeedHelp } from "./sections/NeedHelp";
import { SuggestedActions } from "./sections/SuggestedActions";

interface Section {
  id: string;
  component: React.FC<{ isSuggestedActionsEnabled: boolean }>;
}

const HeaderSection: Section = {
  id: "header",
  component: Header,
};

const SuggestedActionsItemSection: Section = {
  id: "suggested_actions",
  component: SuggestedActions,
};

const ActionItemSection: Section = {
  id: "action_items",
  component: ActionItems,
};

const CreditorBalanceSection: Section = {
  id: "creditor_balance",
  component: CreditorBalance,
};

const ReportingSection: Section = {
  id: "reporting",
  component: () => (
    <ReportingContextProvider>
      <Reporting />
    </ReportingContextProvider>
  ),
};

const LastActivitySection: Section = {
  id: "last_activity",
  component: QuickActions,
};

const NeedHelpSection: Section = {
  id: "need_help",
  component: NeedHelp,
};

const NonActivatedMerchantSections = [
  HeaderSection,
  ActionItemSection,
  CreditorBalanceSection,
  LastActivitySection,
  NeedHelpSection,
];

const getReportingSection = (isReportingEnabled: boolean) => {
  if (isReportingEnabled) {
    return ReportingSection;
  }

  return CreditorBalanceSection;
};

interface ContextProps {
  isActivatedMerchant: boolean;
  isReportingEnabled: boolean;
  isSuggestedActionsEnabled: boolean;
}
const getActivatedMerchantSections = ({
  isReportingEnabled,
  isSuggestedActionsEnabled,
}: ContextProps) => [
  ActionItemSection,
  ...(isSuggestedActionsEnabled ? [SuggestedActionsItemSection] : []),
  HeaderSection,
  getReportingSection(isReportingEnabled),
  LastActivitySection,
  NeedHelpSection,
];

const getSections = (context: ContextProps): Section[] =>
  context.isActivatedMerchant
    ? getActivatedMerchantSections(context)
    : NonActivatedMerchantSections;

export const Home: React.FC = () => {
  useSendPageViewEvent(TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_PAGE_VIEWED);
  const { isActivatedMerchant } = useMerchantMaturity();

  const { isVariationOn: isReportingEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.MERCHANT_ENGAGEMENT_REPORTING_V1,
  });

  const { isVariationOn: isSuggestedActionsEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.ASAP_PAYMENTS_SUGGESTED_ACTIONS,
  });

  const sections = getSections({
    isActivatedMerchant,
    isReportingEnabled,
    isSuggestedActionsEnabled,
  });

  return (
    <PrimaryPageLayout
      gutterH={[1, null, null, 4]}
      highlightedMenuLink={Route.Home}
    >
      <Interpose node={<Space v={[3, null, 3]} />}>
        {sections.map(({ component: Component, id }) => (
          <Fragment key={id}>
            <Component isSuggestedActionsEnabled={isSuggestedActionsEnabled} />
          </Fragment>
        ))}
      </Interpose>
    </PrimaryPageLayout>
  );
};
