import {
  AlignItems,
  Box,
  Breakpoint,
  ButtonGutter,
  ButtonSize,
  ButtonVariant,
  Color,
  ColorPreset,
  DataTable,
  DataTableAPI,
  DataTableCompact,
  DataTableResponsive,
  FontWeight,
  Glyph,
  H2,
  Icon,
  IconButton,
  JustifyContent,
  MoneyText,
  MoneyTextVariant,
  P,
  Space,
  Text,
  TextAlign,
  Theme,
  Tooltip,
  TypePreset,
  useDataTable,
  useTheme,
  Visibility,
} from "@gocardless/flux-react";
import { useCallback, useMemo } from "react";
import {
  currencyToCountryIconName,
  currencyToName,
} from "src/common/currencies";
import {
  CreditorBalanceResource,
  CreditorResource,
  Currency,
  CurrencyExchangeRateResource,
} from "@gocardless/api/dashboard/types";
import { useI18n, UserLocale } from "src/technical-integrations/i18n";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { I18n } from "@lingui/core";

import { FundCategoryTooltipCopy } from "../utils/sharedTranslations";

import { BalanceType, FundCategoryType } from "./constants";
import {
  creditorBalanceAmount,
  getBalanceType,
  orderCreditorBalances,
  useCreditorBalancesLastUpdatedAt,
} from "./utils";
import { PayoutTimingsText } from "./PayoutTimingsText";
import { LastUpdatedText } from "./LastUpdatedText";

const UNIT_IN_CENTS = 100;

export const CreditorBalancesMultiCurrency: React.FC<{
  creditorBalanceList: CreditorBalanceResource[];
  creditor: CreditorResource | null | undefined;
  exchangeRates: CurrencyExchangeRateResource[] | undefined;
}> = ({ creditorBalanceList, creditor, exchangeRates }) => {
  const { theme } = useTheme();
  const [locale] = useI18n();
  const { i18n } = useLingui();

  const lastUpdatedAt = useCreditorBalancesLastUpdatedAt(creditorBalanceList);

  const getHeadingStyleProps = useCallback(
    ({ accessor }: DataTableAPI.HeadingInstance<CreditorBalanceResource>) => {
      switch (accessor) {
        case "pending":
        case "confirmed":
        case "closest_payout_amount":
          return { textAlign: TextAlign.Right };
        default:
          return {};
      }
    },
    []
  );

  const options = useMemo<
    DataTableAPI.UseDataTableOptions<CreditorBalanceResource>
  >(
    () =>
      getOptions({
        locale,
        theme,
        creditorBalanceList,
        creditor,
        exchangeRates,
        i18n,
      }),
    [creditorBalanceList, locale, theme, creditor, exchangeRates, i18n]
  );

  const instance = useDataTable(options);

  return (
    <>
      <Box
        borderColor={ColorPreset.BorderOnLight_04}
        borderWidth={1}
        borderRadius={1}
        bg={Color.White}
        gutterH={2}
        gutterV={1}
      >
        <DataTableResponsive
          dataTable={
            <DataTable
              {...instance}
              getHeadingStyleProps={getHeadingStyleProps}
              hideBottomBorder
            />
          }
          dataTableCompact={
            <DataTableCompact {...instance} hideBottomBorder hideTopBorder />
          }
          tableLayoutBreakpoint={Breakpoint.Md}
        />
      </Box>
      <Space v={1.5} />
      <Visibility visible={["none", null, "block"]}>
        <Box gutterH={1.5}>
          <P
            preset={TypePreset.Body_01}
            color={ColorPreset.TextOnLight_02}
            textAlign={TextAlign.Right}
          >
            <LastUpdatedText dateTimeString={lastUpdatedAt} />
          </P>
        </Box>
      </Visibility>
    </>
  );
};

const getOptions = ({
  locale,
  theme,
  creditorBalanceList,
  creditor,
  exchangeRates,
  i18n,
}: {
  locale: UserLocale;
  theme: Theme;
  creditorBalanceList: CreditorBalanceResource[];
  creditor: CreditorResource | null | undefined;
  exchangeRates?: CurrencyExchangeRateResource[];
  i18n: I18n;
}): DataTableAPI.UseDataTableOptions<CreditorBalanceResource> => {
  const updateBalanceList = orderCreditorBalances(
    creditorBalanceList,
    creditor
  );

  const CurrencyHeaderRenderer: React.FC = () => (
    <Visibility visible={["none", "block"]}>
      <Box>
        <Trans id="home.collection-currency">Collection currency</Trans>
      </Box>
    </Visibility>
  );

  const PendingHeaderRenderer: React.FC = () => (
    <Box layout="flex" alignItems={AlignItems.FlexEnd}>
      <P>
        <Trans id="pending">Pending</Trans>
      </P>
      <Space layout="inline" h={0.5} />
      <Tooltip
        message={
          <FundCategoryTooltipCopy
            fundCategoryType={FundCategoryType.PENDING}
          />
        }
        triggeredBy="click"
      >
        {(triggerProps) => (
          <IconButton
            {...triggerProps}
            size={{ base: ButtonSize.Md, gutters: ButtonGutter.Sm }}
            icon={Glyph.Tooltip}
            label="See more information about pending amounts"
            css={{ color: theme.color(ColorPreset.IconOnLight_04) }}
            variant={ButtonVariant.TextAuto}
          />
        )}
      </Tooltip>
    </Box>
  );

  const ConfirmedHeaderRenderer: React.FC = () => (
    <Box layout="flex" alignItems={AlignItems.Center}>
      <P>
        <Trans id="collected">Collected</Trans>
      </P>
      <Space layout="inline" h={0.5} />
      <Tooltip
        message={
          <FundCategoryTooltipCopy
            fundCategoryType={FundCategoryType.COLLECTED}
            isNegative={creditorBalanceList.some(
              ({ confirmed }) => confirmed && Number(confirmed) < 0
            )}
          />
        }
        triggeredBy="click"
      >
        {(triggerProps) => (
          <IconButton
            {...triggerProps}
            size={{ base: ButtonSize.Md, gutters: ButtonGutter.Sm }}
            icon={Glyph.Tooltip}
            label="See more information about confirmed amounts"
            css={{ color: theme.color(ColorPreset.IconOnLight_04) }}
            variant={ButtonVariant.TextAuto}
          />
        )}
      </Tooltip>
    </Box>
  );

  const PaidOutHeaderRenderer: React.FC = () => (
    <Box layout="flex" alignItems={AlignItems.Center}>
      <P>
        <Trans id="payout">Payout</Trans>
      </P>
      <Space layout="inline" h={0.5} />
      <Tooltip
        message={
          <FundCategoryTooltipCopy fundCategoryType={FundCategoryType.PAYOUT} />
        }
        triggeredBy="click"
      >
        {(triggerProps) => (
          <IconButton
            {...triggerProps}
            size={{ base: ButtonSize.Md, gutters: ButtonGutter.Sm }}
            icon={Glyph.Tooltip}
            label="See more information about paid out amounts"
            css={{ color: theme.color(ColorPreset.IconOnLight_04) }}
            variant={ButtonVariant.TextAuto}
          />
        )}
      </Tooltip>
    </Box>
  );

  const CurrencyCellRenderer = ({
    value,
    item,
  }: {
    value?: string;
    item: CreditorBalanceResource;
  }): React.ReactNode => {
    const balanceType = getBalanceType(item, creditor?.fx_payout_currency);
    const exchangeRate = exchangeRates?.find(
      (rate) => rate.source === item.currency
    );

    return (
      <Box
        layout="flex"
        justifyContent={[
          JustifyContent.Start,
          JustifyContent.FlexEnd,
          JustifyContent.FlexStart,
        ]}
      >
        <Box layout="flex">
          <Box spaceAfter={0.5} layout="flex">
            <Icon
              name={currencyToCountryIconName[value as Currency]}
              size={["32px", "24px"]}
            />
          </Box>
          <Space layout="inline" h={0.25} />
        </Box>
        <Box
          layout="flex"
          alignItems={
            balanceType === BalanceType.FXBalance && exchangeRate
              ? AlignItems.Start
              : AlignItems.Center
          }
          flexDirection={
            balanceType === BalanceType.FXBalance && exchangeRate
              ? "column"
              : "row"
          }
        >
          <H2 preset={TypePreset.Body_02} color={ColorPreset.TextOnLight_01}>
            <Text weight={FontWeight.SemiBold}>
              {i18n._(currencyToName()[value as Currency])}{" "}
            </Text>
            <Text weight={FontWeight.Normal}>({value})</Text>
          </H2>
          {getBalanceTypeContent(balanceType, exchangeRate)}
        </Box>
      </Box>
    );
  };

  const getBalanceTypeContent = (
    balanceType: BalanceType | undefined,
    exchangeRate: CurrencyExchangeRateResource | undefined
  ) => {
    switch (balanceType) {
      case BalanceType.HomeBalance:
        return (
          <Icon
            name={Glyph.Home}
            color={ColorPreset.TextOnDark_02}
            css={{ marginLeft: "8px" }}
            data-testid="home-icon"
          />
        );
      case BalanceType.DomesticBalance:
        return (
          <Icon
            name={Glyph.BankAccount}
            color={ColorPreset.TextOnDark_02}
            css={{ marginLeft: "8px" }}
            data-testid="bank-icon"
          />
        );
      case BalanceType.FXBalance:
        return exchangeRate ? (
          <Text
            weight={FontWeight.Normal}
            preset={TypePreset.Body_03}
            color={ColorPreset.TextOnLight_03}
            data-testid="exchange-rate"
          >
            <MoneyText
              amount={UNIT_IN_CENTS / 100}
              currency={exchangeRate?.source as string}
              locale={locale}
              variant={MoneyTextVariant.Flat}
            />
            {" ≈ "}
            <MoneyText
              amount={(UNIT_IN_CENTS / 100) * Number(exchangeRate?.rate)}
              currency={exchangeRate?.target as string}
              locale={locale}
              variant={MoneyTextVariant.Flat}
            />
          </Text>
        ) : null;
    }
    return null;
  };

  const PendingCellRenderer = ({
    item,
  }: {
    item: CreditorBalanceResource;
  }): React.ReactNode => {
    const pendingCellAmount = Number(
      (creditorBalanceAmount(item, FundCategoryType.PENDING) / 100).toFixed(2)
    );

    return (
      <Box
        layout="flex"
        justifyContent={[JustifyContent.Start, JustifyContent.FlexEnd]}
      >
        <P
          color={ColorPreset.TextOnLight_01}
          weight={FontWeight.Bold}
          preset={TypePreset.Body_03}
        >
          <MoneyText
            data-testid={`pending-amount-${item.currency}`}
            amount={pendingCellAmount}
            currency={item.currency as Currency}
            locale={locale}
            color={
              pendingCellAmount < 0
                ? ColorPreset.AlertTextOnLight
                : ColorPreset.TextOnLight_01
            }
            weight={[FontWeight.Bold, null, FontWeight.Normal]}
            variant={MoneyTextVariant.Flat}
          />
        </P>
      </Box>
    );
  };

  const ConfirmedCellRenderer = ({
    item,
  }: {
    item: CreditorBalanceResource;
  }): React.ReactNode => {
    const confirmedCellAmount = Number(
      (creditorBalanceAmount(item, FundCategoryType.COLLECTED) / 100).toFixed(2)
    );

    return (
      <Box
        layout="flex"
        justifyContent={[JustifyContent.Start, JustifyContent.FlexEnd]}
        alignItems={[AlignItems.Start, AlignItems.FlexEnd]}
        flexDirection="column"
      >
        <P
          color={ColorPreset.TextOnLight_01}
          weight={FontWeight.Bold}
          preset={TypePreset.Body_03}
        >
          <MoneyText
            data-testid={`confirmed-amount-${item.currency}`}
            amount={confirmedCellAmount}
            currency={item.currency as Currency}
            locale={locale}
            color={
              confirmedCellAmount < 0
                ? ColorPreset.AlertTextOnLight
                : ColorPreset.TextOnLight_01
            }
            weight={[FontWeight.Bold, null, FontWeight.Normal]}
            variant={MoneyTextVariant.Flat}
          />
        </P>
      </Box>
    );
  };

  const PaidOutCellRenderer = ({
    item,
  }: {
    item: CreditorBalanceResource;
  }): React.ReactNode => {
    const hasUpcomingPayments: boolean =
      (item?.upcoming_payout_amount &&
        Number(item?.upcoming_payout_amount) > 0 &&
        item.closest_payout_amount !== item.upcoming_payout_amount) ||
      false;

    const paidOutCellAmount = Number(
      (
        creditorBalanceAmount(
          item,
          hasUpcomingPayments
            ? FundCategoryType.UPCOMING
            : FundCategoryType.PAYOUT
        ) / 100
      ).toFixed(2)
    );

    return (
      <Box
        layout="flex"
        alignItems={[AlignItems.Start, AlignItems.FlexEnd]}
        flexDirection="column"
      >
        <P
          color={
            paidOutCellAmount <= 0 || hasUpcomingPayments
              ? ColorPreset.TextOnLight_03
              : ColorPreset.TextOnLight_01
          }
          weight={FontWeight.Bold}
          preset={TypePreset.Body_03}
        >
          <MoneyText
            data-testid={
              hasUpcomingPayments
                ? `upcoming-payout-${item.currency}`
                : `paid-out-amount-${item.currency}`
            }
            amount={paidOutCellAmount}
            currency={item.fx_currency || item.currency || Currency.Gbp}
            locale={locale}
            variant={MoneyTextVariant.Flat}
          />
        </P>
        <P preset={TypePreset.Body_01} color={ColorPreset.TextOnLight_03}>
          <PayoutTimingsText
            hasUpcomingPayments={hasUpcomingPayments}
            amount={paidOutCellAmount}
            payoutDate={item.closest_payout_date as unknown as string}
            payoutId={item.closest_payout_id as string | undefined}
          />
        </P>
      </Box>
    );
  };

  return {
    headings: [
      {
        label: i18n._(
          t({
            id: "currency",
            message: "Currency",
          })
        ),
        accessor: "currency",
      },
      {
        label: i18n._(
          t({
            id: "pending",
            message: "Pending",
          })
        ),
        accessor: "pending",
      },
      {
        label: i18n._(
          t({
            id: "confirmed",
            message: "Confirmed",
          })
        ),
        accessor: "confirmed",
      },
      {
        label: i18n._(
          t({
            id: "paid-out",
            message: "Paid out",
          })
        ),
        accessor: "closest_payout_amount",
      },
    ],
    headingRenderers: {
      currency: CurrencyHeaderRenderer,
      pending: PendingHeaderRenderer,
      confirmed: ConfirmedHeaderRenderer,
      closest_payout_amount: PaidOutHeaderRenderer,
    },
    data: updateBalanceList,
    cellRenderers: {
      currency: CurrencyCellRenderer,
      pending: PendingCellRenderer,
      confirmed: ConfirmedCellRenderer,
      closest_payout_amount: PaidOutCellRenderer,
    },
    getItemId: (item: CreditorBalanceResource) => item.currency || "",
  };
};
