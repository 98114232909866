import {
  AlignItems,
  Box,
  ButtonGroup,
  ButtonVariant,
  Color,
  Dialog,
  H3,
  JustifyContent,
  Link,
  P,
  TypePreset,
} from "@gocardless/flux-react";
import { useToggle } from "react-use";
import { Trans } from "@lingui/macro";
import Image from "next/image";
import { Route } from "src/common/routing";
import { LinkBuilder } from "src/components/routing";
import SubscriptionTemplatesSVG from "src/assets/svg/suggested-actions-dialog-subscription-templates.svg";

import { ActionComponentProps } from "../types";

export const TrySubscriptionTemplates = ({
  children,
}: ActionComponentProps) => {
  const [isOpen, toggleDialog] = useToggle(false);
  return (
    <>
      {children({ onClick: toggleDialog })}
      <Dialog
        open={isOpen}
        aria-labelledby="try-subscription-template-dialog-title"
        footer={
          <ButtonGroup arrangement={["column-center", null, null, "row-end"]}>
            <LinkBuilder route={Route.SubscriptionTemplatesCreate}>
              {(result) => (
                <Link variant={ButtonVariant.PrimaryOnLight} {...result}>
                  Try it now
                </Link>
              )}
            </LinkBuilder>
          </ButtonGroup>
        }
        closeAction={{
          label: <Trans id="Close">Close</Trans>,
          onClose: toggleDialog,
        }}
      >
        <Box
          layout="flex"
          bg={Color.Dusk_200}
          gutterV={2}
          alignItems={AlignItems.Center}
          justifyContent={JustifyContent.Center}
          spaceBelow={2}
        >
          <Image
            src={SubscriptionTemplatesSVG}
            alt="image"
            width={96}
            height={96}
          />
        </Box>

        <H3 spaceBelow={1} preset={TypePreset.Heading_03}>
          Try Subscription templates
        </H3>
        <P>
          Subscription templates provide a way to set up multiple customers on
          the same recurring subscription, with no need to create and manage
          them individually
        </P>
        <br />
        <P>
          Once created, you can add <b>new</b> or <b>existing customers</b> to
          the templates directly.
        </P>
      </Dialog>
    </>
  );
};
