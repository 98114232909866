import {
  Box,
  ColorPreset,
  FontWeight,
  Glyph,
  HoverEffect,
  Icon,
  Interpose,
  PlainButton,
  Separator,
  Text,
  TypePreset,
  Visibility,
  XYGrid,
} from "@gocardless/flux-react";
import { useToggle } from "react-use";

import { useSuggestedActions } from "./useSuggestedActions";
import {
  SUGGESTED_ACTION_TILE_HEIGHT,
  SuggestedActionTile,
} from "./components/SuggestedActionTile";

export const SuggestedActions = () => {
  const [isOpen, toggleOpen] = useToggle(false);

  const rawSuggestedActions = useSuggestedActions();

  const filteredActions = rawSuggestedActions.filter(
    ({ shouldShow }) => shouldShow
  );
  const numberOfActions = filteredActions.length;
  const suggestedActions = filteredActions.map(
    ({ ActionComponent, link, ...props }) =>
      typeof link !== "undefined" ? (
        <SuggestedActionTile
          key={props.id}
          onClick={undefined}
          link={link}
          {...props}
        />
      ) : (
        <>
          <ActionComponent>
            {({ onClick }) => (
              <SuggestedActionTile
                key={props.id}
                link={undefined}
                onClick={onClick}
                {...props}
              />
            )}
          </ActionComponent>
        </>
      )
  );

  return (
    <>
      <Visibility visible={["none", null, null, "block"]}>
        <XYGrid columnGap={2} templateColumns="1fr 1fr 1fr">
          {suggestedActions}
        </XYGrid>
      </Visibility>
      <Visibility visible={["block", null, null, "none"]}>
        <Box
          borderWidth={1}
          borderColor={ColorPreset.BorderOnLight_04}
          borderRadius={1}
          gutterH={1.5}
        >
          <Interpose node={<Separator />}>
            {suggestedActions.slice(0, 2)}

            {
              <Box
                css={{
                  maxHeight: isOpen
                    ? (numberOfActions - 2) * SUGGESTED_ACTION_TILE_HEIGHT
                    : 0,
                  transition: "max-height .3s",
                  overflow: "hidden",
                }}
              >
                <Interpose node={<Separator />}>
                  {suggestedActions.slice(2 - numberOfActions)}
                </Interpose>
              </Box>
            }
          </Interpose>
          <PlainButton
            effect={HoverEffect.TextDecoration}
            onClick={toggleOpen}
            css={{ width: "100%" }}
          >
            <Box layout="flex" width="100%" gutterV={1.5}>
              <Box flexGrow={1}>
                <Text preset={TypePreset.Body_01} weight={FontWeight.SemiBold}>
                  {isOpen ? "Show less" : "Show more"}
                </Text>
              </Box>
              <Icon
                name={isOpen ? Glyph.ChevronUp : Glyph.ChevronDown}
                size="14px"
              />
            </Box>
          </PlainButton>
        </Box>
      </Visibility>
    </>
  );
};
