import {
  NotificationPayload,
  useToastNotification,
} from "src/common/notifications/useToastNotification";
import { HTTPError } from "@gocardless/api/utils/api";
import { captureException } from "src/technical-integrations/sentry/sentry";
import {
  Error as httpError,
  Errors,
  ErrorInterface,
  getErrorJSONFromErrorResponse,
} from "@gocardless/api/utils/error";
import { FieldPath, FieldValues, UseFormSetError } from "react-hook-form";
import { P } from "@gocardless/flux-react";
import _ from "lodash";

const isValidationError = (errorObject: ErrorInterface): boolean =>
  _.get(errorObject, "type") === "validation_failed";

const errorHasField = (errorObject: ErrorInterface): boolean =>
  "field" in _.get(errorObject, "errors[0]", {});

export const isFormError = (response: ErrorInterface): boolean =>
  isValidationError(response) && errorHasField(response);

export const getErrorNotification = async (
  error: HTTPError
): Promise<{
  notificationPayload: NotificationPayload;
  formError: Errors | undefined | "";
  errorJSON: ErrorInterface | undefined;
}> => {
  const errorJSON = await getErrorJSONFromErrorResponse(error);
  let notificationPayload: NotificationPayload;
  let formError: Errors | undefined | "";
  if (errorJSON) {
    notificationPayload = {
      message: <P>{errorJSON.message}</P>,
      hideOnMobile: false,
      duration: Infinity,
    };
    if (isFormError(errorJSON)) {
      formError = errorJSON.errors;
    }
  } else {
    notificationPayload = error;
    formError = "";
  }

  return {
    notificationPayload,
    formError,
    errorJSON,
  };
};

export function useHandleFormError<T extends FieldValues>(
  setError: UseFormSetError<T>
): (error: Error) => Promise<{
  notificationPayload: NotificationPayload;
  formError: "" | Errors | undefined;
  errorJSON: ErrorInterface | undefined;
}> {
  const { triggerErrorNotification } = useToastNotification();

  const getErrorMessage = (error: httpError) => {
    if (error.field === "amount") {
      const amountInPence =
        Number(
          error.metadata.maximum_amount || error.metadata.minimum_amount
        ) || 0;

      const poundComponent = Math.floor(amountInPence / 100);
      const penceComponent = (amountInPence % 100).toString().padStart(2, "0");

      return `${error.field} ${error.message} of ${poundComponent}.${penceComponent}`;
    }
    return `${error.field}: ${error.message}`;
  };

  const setErrorForFields = (formError: Errors | undefined | "") => {
    if (formError) {
      for (const error of formError) {
        if (error.field) {
          setError(error.field as FieldPath<T>, {
            type: "custom",
            message: getErrorMessage(error),
          });
        }
      }
    }
  };

  return async (error: Error) => {
    const errorPayload = await getErrorNotification(error as HTTPError);
    triggerErrorNotification(errorPayload.notificationPayload);
    setErrorForFields(errorPayload.formError);
    captureException({
      error: error as HTTPError,
    });
    return errorPayload;
  };
}
