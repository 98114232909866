import { LeaveFeedback } from "src/components/navigation/LeaveFeedback";
import { Route, RouteURLParams } from "src/common/routing";
import MFASvg from "src/assets/svg/suggested-actions-2fa.svg";
import LeaveFeedbackSvg from "src/assets/svg/suggested-actions-leave-feedback.svg";
import SubscriptionTemplatesSVG from "src/assets/svg/suggested-actions-subscription-templates.svg";
import { useLocalStorage } from "react-use";

import {
  useTwoFactorAuthenticationShowMessage,
  STORAGE_KEY as TWO_FACTOR_STORAGE_KEY,
} from "../../components/TwoFactorAuthenticationBanner";

import { ActionComponentProps } from "./types";
import { TrySubscriptionTemplates } from "./dialogs/TrySubscriptionTemplates";

interface SuggestedActionDataBaseType {
  id: string;
  message: string;
  title: string;
  shouldShow?: boolean;
  imageComponent: string;
}

type SuggestedActionWithDialog = SuggestedActionDataBaseType & {
  ActionComponent: React.FC<ActionComponentProps>;
  link: undefined;
};

type SuggestedActionWithLink = SuggestedActionDataBaseType & {
  link: RouteURLParams;
  ActionComponent: undefined;
};

export type SuggestedActionProps =
  | SuggestedActionWithLink
  | SuggestedActionWithDialog;

export const useSuggestedActions = (): SuggestedActionProps[] => {
  const [storageItem] = useLocalStorage(TWO_FACTOR_STORAGE_KEY, "");
  const show2FABanner = useTwoFactorAuthenticationShowMessage({
    isBannerDismissedUntilDateString: storageItem,
  });

  return [
    {
      id: "leave_feedback",
      message:
        "Tell us about your experience to help us make GoCardless better",
      title: "Share your feedback ",
      shouldShow: true,
      imageComponent: LeaveFeedbackSvg,
      ActionComponent: LeaveFeedback,
      link: undefined,
    },
    {
      id: "2fa",
      message: "Add extra security to your account",
      title: "Enable two-factor authentication",
      shouldShow: show2FABanner,
      imageComponent: MFASvg,
      ActionComponent: undefined,
      link: { route: Route.MFASetup },
    },
    {
      id: "subscription_templates",
      message: "Save time on admin and manage multiple subscriptions at once",
      title: "Try Subscription templates",
      shouldShow: true,
      imageComponent: SubscriptionTemplatesSVG,
      ActionComponent: TrySubscriptionTemplates,
      link: undefined,
    },
  ];
};
