import {
  Box,
  Color,
  ColorPreset,
  H6,
  HoverEffect,
  P,
  PlainButton,
  PlainLink,
  Space,
  TypePreset,
  Visibility,
} from "@gocardless/flux-react";
import Image from "next/image";
import { RouteURLParams } from "src/common/routing";
import { LinkBuilder } from "src/components/routing";

export interface SuggestedActionTileBaseProps {
  id: string;
  message: React.ReactNode;
  title: React.ReactNode;
  imageComponent: string;
}

type SuggestedActionTileWithClick = SuggestedActionTileBaseProps & {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  link: undefined;
};

type SuggestedActionTileWithLink = SuggestedActionTileBaseProps & {
  link: RouteURLParams;
  onClick: undefined;
};

export type SuggestedActionProps =
  | SuggestedActionTileWithClick
  | SuggestedActionTileWithLink;

export const SUGGESTED_ACTION_TILE_HEIGHT = 350;

export const SuggestedActionInnerTile = (
  props: SuggestedActionTileBaseProps
) => (
  <Box
    gutterH={[0, null, null, 1.5]}
    gutterV={1.5}
    layout="flex"
    bg={[Color.Transparent, null, null, Color.White]}
    borderRadius={[0, null, null, 1]}
    borderWidth={[0, null, null, 1]}
    borderColor={ColorPreset.BorderOnLight_04}
    flexDirection={["row-reverse", null, "row"]}
    height="100%"
  >
    {props.imageComponent && (
      <>
        <Visibility visible={["none", null, null, "block"]}>
          <Image
            width={48}
            height={48}
            src={props.imageComponent}
            alt={props.title?.toLocaleString() ?? ""}
          />
        </Visibility>
        <Visibility visible={["block", null, null, "none"]}>
          <Image
            width={40}
            height={40}
            src={props.imageComponent}
            alt={props.title?.toLocaleString() ?? ""}
          />
        </Visibility>
      </>
    )}
    <Space layout="inline" h={1} />
    <Box flexGrow={1}>
      <H6 spaceBelow={0.5} preset={TypePreset.Heading_02}>
        {props.title}
      </H6>
      <P preset={TypePreset.Caption_01}>{props.message}</P>
    </Box>
  </Box>
);

export const SuggestedActionTile = (props: SuggestedActionProps) => {
  if (typeof props.link !== "undefined")
    return (
      <LinkBuilder {...props.link}>
        {(result) => (
          <PlainLink {...result} css={{ width: "100%" }}>
            <SuggestedActionInnerTile {...props} />
          </PlainLink>
        )}
      </LinkBuilder>
    );

  return (
    <PlainButton
      effect={HoverEffect.TextDecoration}
      title={props.id ?? ""}
      onClick={props.onClick}
    >
      <SuggestedActionInnerTile {...props} />
    </PlainButton>
  );
};
