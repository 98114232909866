import {
  CreditorBalanceResource,
  Currency,
} from "@gocardless/api/dashboard/types";
import {
  AlignItems,
  Box,
  ButtonGutter,
  ButtonSize,
  ButtonVariant,
  Color,
  ColorPreset,
  FontWeight,
  Glyph,
  H2,
  H3,
  Icon,
  IconButton,
  JustifyContent,
  MoneyText,
  P,
  Space,
  Text,
  Tooltip,
  TypePreset,
  TypeScale,
  Visibility,
} from "@gocardless/flux-react";
import {
  currencyToCountryIconName,
  currencyToName,
} from "src/common/currencies";
import { useI18n } from "src/technical-integrations/i18n";
import { round } from "lodash";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { FundCategoryTooltipCopy } from "../utils/sharedTranslations";

import { FundCategoryType } from "./constants";
import {
  creditorBalanceAmount,
  useCreditorBalancesLastUpdatedAt,
} from "./utils";
import { PayoutTimingsText } from "./PayoutTimingsText";
import { LastUpdatedText } from "./LastUpdatedText";

export interface CarouselConfigItem {
  title: string;
  type: FundCategoryType;
  tooltipCopy: React.ReactNode;
  color: Color;
}

const LAST_PAYOUT_INTERPOLATION_SYMBOL = "{LAST_PAYOUT}";

export interface CreditorBalancesSingleCurrencyProps {
  creditorBalance: CreditorBalanceResource;
  children: ({
    cardConfig,
  }: {
    cardConfig: ReadonlyArray<CarouselConfigItem>;
  }) => JSX.Element;
}

export const CreditorBalanceCard: React.FC<{
  card: CarouselConfigItem;
  creditorBalance: CreditorBalanceResource;
}> = ({ card, creditorBalance }) => {
  const [locale] = useI18n();

  const amount = round(
    creditorBalanceAmount(creditorBalance, card.type) / 100,
    2
  );
  const contentColor =
    card.type === FundCategoryType.PAYOUT && amount === 0
      ? ColorPreset.TextOnLight_03
      : card.type === FundCategoryType.COLLECTED && amount < 0
        ? ColorPreset.AlertTextOnLight
        : ColorPreset.TextOnLight_01;

  const titleText = (): React.ReactNode => {
    if (card.type === FundCategoryType.PAYOUT) {
      return (
        <PayoutTimingsText
          amount={amount}
          payoutDate={creditorBalance.closest_payout_date as unknown as string}
          payoutId={creditorBalance.closest_payout_id as string | undefined}
        />
      );
    } else {
      return card.title;
    }
  };

  return (
    <Box
      key={card.type}
      gutterV={[2, null, 3]}
      gutterH={[2, null, 3]}
      borderRadius={1}
      bg={ColorPreset.BackgroundLight_01}
      borderWidth={1}
      borderColor={ColorPreset.BorderOnLight_04}
      width={["100%", null, "33.33%"]}
    >
      <Box layout="flex" alignItems={AlignItems.Center}>
        <H3
          preset={TypePreset.Subheading_01}
          size={TypeScale.Size_04}
          color={ColorPreset.TextOnLight_01}
          weight={FontWeight.Light}
        >
          {titleText()}
        </H3>
        <Space layout="inline" h={0.5} />
        <Tooltip message={card.tooltipCopy} triggeredBy="click">
          {(triggerProps) => (
            <IconButton
              {...triggerProps}
              size={{ base: ButtonSize.Md, gutters: ButtonGutter.Sm }}
              icon={Glyph.Tooltip}
              label={`See more information about ${titleText()}`}
              variant={ButtonVariant.TextAuto}
            />
          )}
        </Tooltip>
      </Box>
      <Space v={0.5} />
      <MoneyText
        data-testid={`amount-${card.type}`}
        amount={amount}
        currency={creditorBalance.currency as Currency}
        locale={locale}
        preset={TypePreset.Heading_05}
        color={contentColor}
      />
      <Space v={1} />
      <Box height="6px" bg={card.color} borderRadius={0.25} />
    </Box>
  );
};

export const CreditorBalancesSingleCurrency: React.FC<
  CreditorBalancesSingleCurrencyProps
> = ({ creditorBalance, children }) => {
  const { i18n } = useLingui();

  const lastUpdatedAt = useCreditorBalancesLastUpdatedAt(creditorBalance);

  const cardConfig: ReadonlyArray<CarouselConfigItem> = [
    {
      type: FundCategoryType.PENDING,
      title: i18n._(
        t({
          id: "pending",
          message: "Pending",
        })
      ),
      tooltipCopy: (
        <FundCategoryTooltipCopy fundCategoryType={FundCategoryType.PENDING} />
      ),
      color: Color.Sunrise_700,
    },
    {
      type: FundCategoryType.COLLECTED,
      title: i18n._(
        t({
          id: "collected",
          message: "Collected",
        })
      ),
      tooltipCopy: (
        <FundCategoryTooltipCopy
          fundCategoryType={FundCategoryType.COLLECTED}
          isNegative={Number(creditorBalance.confirmed) < 0}
        />
      ),
      color: Color.Success_100,
    },
    {
      type: FundCategoryType.PAYOUT,
      title: i18n._(
        t({
          id: "home.last-payout-text",
          message: `Last payout ${LAST_PAYOUT_INTERPOLATION_SYMBOL}`,
        })
      ),
      tooltipCopy: (
        <FundCategoryTooltipCopy fundCategoryType={FundCategoryType.PAYOUT} />
      ),
      color: Color.Info_300,
    },
  ];

  return (
    <Box>
      <Box
        layout="flex"
        justifyContent={JustifyContent.SpaceBetween}
        alignItems={AlignItems.Center}
      >
        <Box layout="flex" alignItems={AlignItems.Center}>
          <Icon
            name={
              currencyToCountryIconName[creditorBalance.currency as Currency]
            }
            size="24px"
          />
          <Space layout="inline" h={0.75} />
          <H2 preset={TypePreset.Heading_04} color={ColorPreset.TextOnLight_01}>
            {i18n._(currencyToName()[creditorBalance.currency as Currency])}{" "}
            <Text weight={FontWeight.Normal}>({creditorBalance.currency})</Text>
          </H2>
        </Box>
        <Visibility visible={["none", null, "block"]}>
          <Box>
            <P preset={TypePreset.Body_01} color={ColorPreset.TextOnLight_02}>
              <LastUpdatedText dateTimeString={lastUpdatedAt} />
            </P>
          </Box>
        </Visibility>
      </Box>
      <Space v={1.5} />

      {children({ cardConfig })}
    </Box>
  );
};
